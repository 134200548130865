




import {
  Component,
  Vue,
} from 'vue-property-decorator';

@Component({
  name: 'Loader',
})
export default class VueMeetingSelector extends Vue {}
