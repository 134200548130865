
























import {
  Component,
  Prop,
  Emit,
  Vue,
} from 'vue-property-decorator';

import MeetingsDay from '@/interfaces/MeetingsDay.interface';
import MeetingSlot from '@/interfaces/MeetingSlot.interface';

import MeetingDisplay from '@/components/MeetingSelector/MeetingDisplay.vue';

@Component({
  name: 'meetings',
  components: {
    MeetingDisplay,
  },
})
export default class DayDisplay extends Vue {
  @Prop({ required: true })
  readonly meetingsDay!: MeetingsDay;

  @Prop({ default: () => ({}) })
  readonly meetingSlotSelected!: MeetingSlot[] | MeetingSlot

  @Prop({ default: '' })
  readonly meetingClass!: string;

  @Prop({ default: '' })
  readonly meetingButtonClass!: string;

  @Prop({ default: '' })
  readonly meetingEmptyClass!: string;

  meetingSlotClick(meetingSlot: MeetingSlot): void {
    this.$emit('meeting-slot-click', meetingSlot);
  }
}
