






















































































































import {
  Component,
  Model,
  Prop,
  PropSync,
  Vue,
  Watch,
} from 'vue-property-decorator';

import '@/assets/css/icons-font.css';

import MeetingsDay from '@/interfaces/MeetingsDay.interface';
import MeetingSlot from '@/interfaces/MeetingSlot.interface';
import CalendarOptions from '@/interfaces/CalendarOptions.interface';
import ClassNames from '@/interfaces/ClassNames.interface';

import Meetings from '@/components/MeetingSelector/Meetings.vue';
import DayDisplay from '@/components/MeetingSelector/DayDisplay.vue';
import ArrowIcon from '@/components/MeetingSelector/ArrowIcon.vue';
import Loader from '@/components/MeetingSelector/Loader.vue';

import defaultCalendarOptions from '@/defaults/calendarOptions';
import defaultClassNames from '@/defaults/classNames';

@Component({
  name: 'VueMeetingSelector',
  components: {
    Meetings,
    DayDisplay,
    ArrowIcon,
    Loader,
  },
})
export default class VueMeetingSelector extends Vue {
  skip = 0

  @Model('change', { type: [Array, Object] })
  meetingSlot!: any

  @PropSync('date', { type: Date })
  syncedDate!: Date

  @Prop({ required: true })
  readonly meetingsDays!: MeetingsDay[];

  @Prop({ default: () => ({}) })
  readonly calendarOptions!: CalendarOptions;

  @Prop({ default: () => ({}) })
  readonly classNames!: ClassNames;

  @Prop({ default: false })
  readonly loading!: boolean;

  @Prop({ default: false })
  readonly multi!: boolean;

  get days(): string[] {
    const { daysLabel } = this.options;
    return this.meetingsDays.map((meetingsDay) => {
      const date = new Date(meetingsDay.date);
      return daysLabel[date.getDay()];
    });
  }

  get maxNbMeetings(): number {
    if (this.meetingsDays.length) {
      return Math.max(...this.meetingsDays.map(meetingsDay => meetingsDay.slots.length));
    }
    return 0;
  }

  get meetingsByDays(): MeetingsDay[] {
    const arrayIndex = Math.ceil(this.maxNbMeetings / this.options.limit)
      * this.options.limit;
    return this.meetingsDays.map((meetingsDay) => {
      const slots = new Array(arrayIndex).fill({ date: '' });
      slots.splice(0, meetingsDay.slots.length, ...meetingsDay.slots);
      const day = {
        ...meetingsDay,
        slots: slots.slice(this.skip, this.skip + this.options.limit),
      };
      return day;
    });
  }

  get options(): CalendarOptions {
    return {
      ...defaultCalendarOptions,
      ...this.calendarOptions,
    };
  }

  get cssClass(): ClassNames {
    return {
      ...defaultClassNames,
      ...this.classNames,
    };
  }

  tabDaysClass(index: number): string {
    return index + 1 === this.meetingsByDays.length
      ? `tab__days--last ${this.cssClass.tabMeetings}`
      : this.cssClass.tabMeetings || '';
  }

  nextMeetings(): void {
    this.skip += this.options.limit;
  }

  previousMeetings(): void {
    this.skip -= this.options.limit;
  }

  previousDate(): void {
    this.$emit('previous-date');
  }

  nextDate(): void {
    this.$emit('next-date');
  }

  meetingSlotClick(meetingSlot: MeetingSlot): void {
    if (this.multi && Array.isArray(this.meetingSlot)) {
      const selectedDate:number = new Date(meetingSlot.date).getTime();
      const index:number = this.meetingSlot.findIndex((s: MeetingSlot) => {
        const date = new Date(s.date);
        return date.getTime() === selectedDate;
      });
      const slots = [...this.meetingSlot];
      if (index !== -1) {
        slots.splice(index, 1);
        this.$emit('change', slots);
        this.$emit('meeting-slot-selected', slots);
      } else {
        slots.push(meetingSlot);
        this.$emit('change', slots);
        this.$emit('meeting-slot-selected', slots);
      }
      return;
    }
    if (this.meetingSlot && this.meetingSlot as MeetingSlot) {
      const selectedDate = new Date(meetingSlot.date);
      const date = new Date(this.meetingSlot.date);
      if (date.getTime() === selectedDate.getTime()) {
        this.$emit('change', undefined);
        this.$emit('meeting-slot-unselected');
        return;
      }
    }
    this.$emit('change', meetingSlot);
    this.$emit('meeting-slot-selected', meetingSlot);
  }

  @Watch('date')
  onDateChanged() {
    this.skip = 0;
  }
}
