




import { Component, Prop, Vue } from 'vue-property-decorator';


@Component
export default class DayDisplay extends Vue {
  @Prop({ default: 'right' })
  readonly direction!: string;

  get iconDirectionClass() {
    return `vms-icon-${this.direction}`;
  }
}
