



















import {
  Component,
  Prop,
  Emit,
  Vue,
} from 'vue-property-decorator';

import MeetingSlot from '@/interfaces/MeetingSlot.interface';

@Component
export default class MeetingsDisplay extends Vue {
  @Prop({ required: true })
  readonly meetingSlot!: MeetingSlot;

  @Prop({ default: null })
  readonly meetingSlotSelected!: MeetingSlot[] | MeetingSlot;

  @Prop({ default: '' })
  readonly meetingButtonClass!: string;

  @Prop({ default: '' })
  readonly meetingEmptyClass!: string;

  get meetingClass() {
    return {
      [this.meetingButtonClass]: true,
      'meeting__button--selected': this.isMeetingSelected,
    };
  }

  get time() {
    const date = new Date(this.meetingSlot.date);
    const hours = date.getHours() < 10 ? `0${date.getHours()}` : date.getHours();
    const minutes = date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes();
    return `${hours}:${minutes}`;
  }

  get isMeetingSelected(): boolean {
    if (Array.isArray(this.meetingSlotSelected)) {
      const date:number = new Date(this.meetingSlot.date).getTime();
      for (const slot of this.meetingSlotSelected) {
        const d = new Date(slot.date);
        if (d.getTime() === date) {
          return true;
        }
      }
      return false;
    }
    if (this.meetingSlotSelected && this.meetingSlotSelected.date) {
      const meetingSelectedDate = new Date(this.meetingSlotSelected.date);
      const meetingDate = new Date(this.meetingSlot.date);
      return meetingSelectedDate.getTime() === meetingDate.getTime();
    }
    return false;
  }

  @Emit()
  meetingSlotClick() {
    return this.meetingSlot;
  }
}
